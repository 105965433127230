/* eslint-disable @next/next/no-img-element */
import * as constants from "../../constants";

export default function Hello() {
  return (
    <section id="hello" className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 about-img-wrap">
            <div className="about-img wow slideInRight">
              <img
                src="media/portfolio-images/solution-architect-abdallah-albarmawi.png"
                className="img-responsive"
                alt={constants.altText}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-me wow slideInLeft">
              <div className="about-me-title">
                <h2>
                  <span className="point">I am Abdallah Albarmawi</span>
                </h2>
              </div>
              <div className="about-me-text">
                <div className="opacity-box">
                  <p>
                    As a seasoned Senior Software Engineer and Solution
                    Architect with extensive industry experience, I have
                    consistently delivered exceptional results across a diverse
                    range of projects.
                    <br />
                    <br />
                    With a strong foundation in various cutting-edge
                    technologies, I have successfully navigated complex software
                    development landscapes while effectively collaborating with
                    cross-functional teams.
                    <br />
                    <br />
                    From small-scale initiatives to large enterprise endeavors,
                    my track record showcases my adaptability and ability to
                    deliver tailored solutions that meet the unique requirements
                    of each project. With a keen eye for detail and a passion
                    for innovation, I have consistently achieved outstanding
                    outcomes, enabling businesses to thrive in an ever-evolving
                    digital landscape.
                  </p>
                </div>
              </div>
              <div className="about-me-info">
                <p>
                  <span className="span-title">Email</span>
                  <span>abdallah.albarmawi@gmail.com</span>
                </p>
                <p>
                  <span className="span-title">Address</span>
                  <span>Hamburg, Germany</span>
                </p>
                <p>
                  <span className="span-title">Social</span>
                  <span className="span-icons">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/abarmawi/"
                      className="mdi fonts-icons mdi-linkedin"
                    ></a>
                    <a
                      target="_blank"
                      href="https://github.com/abarmawi"
                      className="mdi fonts-icons mdi-github-circle"
                    ></a>
                  </span>
                </p>
              </div>
              <div className="about-btns">
                <a
                  data-toggle="modal"
                  data-target="#contact-modal"
                  href="#"
                  className="site-btn"
                >
                  Contact me
                </a>
                <a
                  href="./download/abdallah-albarmawi-cv-latest.pdf"
                  target="_blank"
                  className="site-btn gray-btn"
                >
                  Download cv
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
